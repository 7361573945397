import React from 'react';

class SearchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString : ""
        }
        this.searchInput = React.createRef();
    }


    componentDidMount() {
        this.searchInput.current.focus();
    }


    handleSearchInputChange = (e) => {
        const v = e.target.value;
        this.props.searchInputHandler([v].filter(Boolean));
        this.setState(() => {
            return {
                searchString: v
            }
        });
    }

    render() {
        return (
            <div className={"tag-list"}>
                <h2>Search</h2>
                <form onSubmit={(e) => { e.preventDefault(); }}>
                    <input ref={this.searchInput} value={this.state.searchString} onChange={this.handleSearchInputChange}/>
                </form>
            </div>
        )
    }
}

export default SearchList;
