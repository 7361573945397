import React from 'react';
import ReactDOM from 'react-dom';
import AlertTemplate from "react-alert-template-basic";
import { positions, Provider } from "react-alert";
import './index.css';
import App from './App';

const options = {
    timeout: 5000,
    offset: '70px',
  position: positions.TOP_CENTER
};

ReactDOM.render(
    <Provider template={AlertTemplate} {...options}>
      <App />
    </Provider>
    ,
    document.getElementById('root'));
