import { TextEncoder, TextDecoder } from 'text-encoding-shim';

const encoder = new TextEncoder();

/**
 * @param {ArrayBuffer} buffer
 */
export function bufferToHex(buffer) {
	return Array
		.from(new Uint8Array(buffer))
		.map((b) => b.toString(16).padStart(2, '0'))
		.join('');
}

/**
 * @param {string} hex
 */
export function hexToBuffer(hex) {
    const pairs = hex.match(/../g);
	return new Uint8Array(pairs.map((b) => parseInt(b, 16)));
}


/**
 * @param {string} password
 * @param {string|undefined} saltString
 */
export async function generateCryptoKey(password, saltString = undefined) {
    const cs = crypto.subtle;
    let salt;
    if (saltString) {
        salt = hexToBuffer(saltString);
    } else {
        salt = crypto.getRandomValues(new Uint8Array(32));
    }
    const passwordKey = await cs.importKey(
        "raw",
        encoder.encode(password),
        'PBKDF2',
        false, //whether the key is extractable (i.e. can be used in exportKey)
        ["deriveKey"]
    );
    const aesKey = await cs.deriveKey({
            name: 'PBKDF2', salt, iterations: 250000,
            hash: {name: 'SHA-256'}
    }, passwordKey, { name: 'AES-GCM', length: 256 }, false, ['encrypt', 'decrypt']);

    return { salt: bufferToHex(salt), aesKey };
}

export async function encrypt(data, cryptoKey) {
    const iv = crypto.getRandomValues(new Uint8Array(32));
    const eData = await crypto.subtle.encrypt({
        name: 'AES-GCM',
        iv
    }, cryptoKey, encoder.encode(data));
    return bufferToHex(iv) + bufferToHex(eData);
}

/**
 * @param {string} data
 * @param {CryptoKey} cryptoKey
 */
export async function decrypt(data, cryptoKey) {
    const iv = hexToBuffer(data.slice(0, 64));
    const eData = hexToBuffer(data.slice(64));
    const ueData = await crypto.subtle.decrypt({
        name: 'AES-GCM',
        iv
    }, cryptoKey, eData);
    return new TextDecoder().decode(ueData);
}
