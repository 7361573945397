import config from '../../config.json';
import test_data from '../../server/db.json';
import * as encryption from '../encryption';

function getExtension(filename) {
    return filename.includes('.') ? filename.split('.').pop() : "";
}

async function fetchJson(url, options) {
    const data = await fetch(url, options);
    console.debug("Fetching", url, options, "->", data);
    if (!data.ok) {
        const body = await data.text();
        throw Error(`Error in fetch (${url}, status=${data.status}): ` + body);
    }
    return data.json();
}

class Db {
    getData() {

    }

    setData(data) {

    }

    /**
     * @param {string} password
     */
    enterPassword(password) {

    }
}

class InternalDb extends Db {
    constructor(data) {
        super();
        this.data = data;
    }
    getData() {
        return this.data;
    }
}

/**
 * @param {string} username
 * @param {string} password
 */
const authentication = (username, password) => {
    console.debug(`Got credentials: user = ${username} and a password`);
    return 'Basic ' + btoa(username + ':' + password);
}

class NoRestDb extends Db {
    constructor(url, username, password) {
        super();
        this.url = url;
        this.data = [];
        this.salt = null;
        this.aesKey = null;
        this.decrypted = false;
        this.authentication = username ? authentication(username, password) : null;
    }
    calcHeaders() {
        const headers = new Headers({
            "Content-Type": "application/json",
        });
        if (this.authentication) {
            headers.append('Authorization', this.authentication);
        }
        return headers;
    }
    async init (){
        return await fetchJson(this.url, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                // mode: "no-cors", // no-cors, cors, *same-origin
                headers: this.calcHeaders(),
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                redirect: "follow", // manual, *follow, error
                referrer: "no-referrer", // no-referrer, *client
        }).then((jsonData) => {
            switch(jsonData.format_version){
                case 1:
                    this.data = jsonData.data;
                    this.salt = jsonData.salt;
                    break;
                default:
                    console.error(`Format version ${jsonData.format_version} not supported`);
                    return;
            }
        });
    }

    getData() {
        return {
            items: this.data
        }
    }
    async setData(data) {
        this.data = data.items;
        const encryptedItems = await this.encrypt(this.data);
        if (encryptedItems && encryptedItems.length === this.data.length) {
            await fetchJson(
                this.url,
                {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    // mode: "no-cors", // no-cors, cors, *same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: this.calcHeaders(),
                    redirect: "follow", // manual, *follow, error
                    referrer: "no-referrer", // no-referrer, *client
                    body: JSON.stringify({ format_version: 1, data: encryptedItems, salt: this.salt }),
                });
        }
    }

    async enterPassword(password) {
        if (this.decrypted) {
            throw new Error("Already decrypted!");
        }
        console.debug("Starting decryption.");
        const { salt, aesKey } = await encryption.generateCryptoKey(password, this.salt);
        this.salt = salt;
        this.aesKey = aesKey;
        return await this.decrypt();
    }

    async decrypt() {
        const aesKey = this.aesKey;
        let c = 0;
        for (const item of this.data) {
            console.debug("Decrypting Item:", item.id);
            item.label = await encryption.decrypt(item.label, this.aesKey);
            item.user = await encryption.decrypt(item.user, aesKey);
            item.pw = await encryption.decrypt(item.pw, aesKey);
            if (!item.tags) {
                item.tags = [];
            }
            c++;
        }
        this.decrypted = true;
        console.info(`Decryption of ${c} items done.`);
        return true;
    }

    async encrypt(items) {
        try {
            const result = [];
            const aesKey = this.aesKey;
            let c = 0;
            for (const item of items) {
                console.debug("Encrypting Item:", item.id);
                result.push({
                    id: item.id,
                    label: await encryption.encrypt(item.label, this.aesKey),
                    user: await encryption.encrypt(item.user, aesKey),
                    pw: await encryption.encrypt(item.pw, aesKey),
                    tags: item.tags
                });
                c++;
            }
            console.info(`Encryption of ${c} items done.`);
            return result;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}



/**
 * @param {string} dbUrl
 * @param {string} username
 * @param {string} password
 */
const createBackend = async (dbUrl ,username, password) => {
    if (dbUrl === 'test') {
        return new Promise((success, reject) => {
            setTimeout(() => success(new InternalDb(test_data)), 1000);
        });
    }

    if (dbUrl.indexOf(":") === -1) {
        //FIXME static delivery doesn't seem to work. Because:
        const base = process.env.PUBLIC_URL; // seems to be empty!
        dbUrl = base + dbUrl;
    }
    if(dbUrl.includes("http")) {
        const url = new URL(dbUrl);
        switch (url.protocol) {
            case "http:":
            case "https:":
                const ext = getExtension(url.pathname);
                if (ext === "json") {
                    return fetchJson(url).then((jsonData) => new InternalDb(jsonData));
                }
                break;
            default:
                throw Error("Unknown protocol in " + url);
        }
    }   else {
        const db = new NoRestDb(dbUrl, username, password);
        await db.init();
        return db;
    }
}

let db;

export const init = async (username, password) => {
    db = await createBackend(config.dbUrl, username, password);
}

export const getTags = () => {
    const m = db.getData().items.reduce((map, item) => {
        const tags = item.tags.length > 0 ? item.tags : [""];
        for (const t of tags) {
            const c = map.has(t) ? map.get(t) : 0;
            map.set(t, c + 1);
        }
        return map;
    }, new Map());
    return m;
}

function normalizeTags(tags) {
    return tags.length ? tags : [""];
}

class SearchEngine {
    /** @type { RegExp[] } */
    searchPatterns = [];
    /**
     * @param { string[] } searchPatterns
     */
    constructor(searchPatterns) {
        this.searchPatterns = searchPatterns.map((p) => {
            return new RegExp(p, 'i');
        });
    }

    /**
     * @param {{ label: string, user: string }} item
     */
    matches(item) {
        for (const p of this.searchPatterns) {
            if (p.test(item.label) || p.test(item.user)) {
                return true;
            }
        }
        return false;
    }
}


export const getItemsForTagsAndSearch = (tags, searchPatterns) => {
    console.debug("Get items for tags", tags);
    const searchEngine = new SearchEngine(searchPatterns);

    return db.getData().items.filter((item) => {
        return searchEngine.matches(item) ||
            hasOverlap(new Set(normalizeTags(item.tags)), new Set(tags))
        });
}

export const setItem = (itemData) => {
    const newItems = [...(db.getData().items.filter((item) => item.id !== itemData.id)), itemData]
    db.setData({ items: newItems });
}

export const addNewItemHandler = (selectedTags) => {
    let maxId = 0;
    db.getData().items.forEach(item => {
        maxId = (item.id > maxId ? item.id : maxId);
    });

    const newItem = {
        "id": maxId + 1,
        "label": "",
        "user": "",
        "pw": "",
        "tags": selectedTags ? [...selectedTags] : [""]
    };

    db.setData({ items: [...db.getData().items, newItem] });
    return newItem;
}

export const deleteItem = (itemId) => {
    console.debug("Delete", itemId);
    const items = db.getData().items;
    const i = items.findIndex((item) => item.id === itemId);
    if (i === -1) {
        throw Error("Could not find " + itemId + " in data!");
    }
    items.splice(i, 1);
    db.setData({ items });
}

export const hasOverlap = (set1, set2) => {
    for (const i of set1) {
        if (set2.has(i)) {
            return true;
        }
    }
    return false;
}

export const enterPassword = async (password) => {
    return await db.enterPassword(password);
}
