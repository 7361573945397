import React from 'react'
import copySvg from './copy.svg'
import ReactHtmlParser from 'react-html-parser';


// eslint-disable-next-line
const urlRe = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;

function urlsToLinks(text) {
    return ReactHtmlParser(text.replace(urlRe,"<a target=\"_blank\" href=\"$1\">$1</a>"));
}

const ItemProp = ({ editMode = false, label, value = "", addClasses = [], dataProp, handleItemPropChange, clickHandler = () => { }, copyHandler = () => {}, children = [] }) => {
    return (
        <div className={"item-prop" + (addClasses.length ? " " + addClasses.join(" ") : "")}>
            {(children.length === 0 ?
                (
                    <React.Fragment>
                        <span className="item-prop_label">{label}</span>
                        {editMode ?
                            (<input value={value} onChange={(e) => handleItemPropChange(dataProp, e.target.value)} />)
                            :
                            (<React.Fragment>{
                                    ['pw', 'user'].includes(dataProp) && (<button title="Copy password to clipboard" className="icon" style={{ marginLeft: 0, marginRight:'5px', backgroundImage: 'url(' + copySvg + ')' }} onClick={copyHandler}></button>)
                                    }<span className="item-prop_value" onClick={editMode ? () => { } : clickHandler}>{dataProp === 'label' ? urlsToLinks(value) : value}</span>
                            </React.Fragment>)
                        }
                    </React.Fragment>
                ) : (
                    <React.Fragment>

                    {
                        children
                    }
                    </React.Fragment>

                ))
            }
        </div>
    )
}

export default ItemProp;