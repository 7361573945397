import React from 'react'
import checkSvg from './check.svg'

class Intro extends React.Component {

    constructor(props) {
        super(props);
        console.debug("data constr: ", props.data);
        this.state = {
            username: "",
            password: "",
        };
        this.userInput = React.createRef();
        this.passwordInput = React.createRef();
    }

    handleInput = (which, e) => {
        const v = e.target.value;
        this.setState(() => {
            switch (which) {
                case 'user':
                    return { username: v };
                case 'password':
                    return { password: v };
                default:
                    throw new Error("Unknown which: " +  which);
            }
        });
    }

    componentDidMount() {
        console.debug("Mounting ", this);
        (this.props.userRequired ?
            this.userInput
            :
            this.passwordInput
        ).current.focus();
    }

    render = () => {
        return (
            <React.Fragment>
                <div className={"intro"}>
                    <form onSubmit={
                            (e) => { e.preventDefault(); this.props.loginHandler(this.state.username, this.state.password); }
                        }>
                        {this.props.userRequired &&
                            <div className="intro_elem">
                                <label>User: </label>
                            <input ref={this.userInput} type="text" value={this.state.user} onChange={(e) => { this.handleInput('user', e) }} />
                            </div>}
                        <div className="intro_elem">
                        <label>{this.props.userRequired ? "Password" : "Master key"}: </label>
                            <input ref={this.passwordInput} type="password" autoComplete={this.props.userRequired ? "on" : "off"} value={this.state.password} onChange={(e) => this.handleInput('password', e)} />
                        </div>
                        <div className="intro_elem">
                            <button type="submit" className="intro_icon" style={{ backgroundImage: 'url(' + checkSvg + ')' }}></button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }
}

export default Intro;
