import React from 'react'
import { createMatchMediaConnect } from 'react-matchmedia-connect';
import editSvg from './edit.svg'
import checkSvg from './check.svg'
import uneditSvg from './unedit.svg'
import deleteSvg from './trash.svg'
import ItemProp from './ItemProp';
import copy from 'clipboard-copy'

const connect = createMatchMediaConnect({
    isBig: '(min-width: 1024px)',
});

function tagsToString(tags) {
    return tags.join(", ");
}
function tagsStringToTags(value) {
    return value.replace(/\s+/g, '').split(",");
}

class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            secretVisible: false,
            firstItemPropWasClicked: false,
            editMode: props.item.label === '',
            editedItemProps: { ...props.item },
            tagsString: tagsToString(props.item.tags)
        };
        this.item = props.item;
        this.notifyEditMode = props.notifyEditMode;
    }

    firstItemPropClickHandler = () => {
        this.setState((oldState) => {
            return { firstItemPropWasClicked: !oldState.firstItemPropWasClicked }
        });
    }

    secretClickHandler = () => {
        this.setState((oldState) => {
            return { secretVisible: !oldState.secretVisible }
        });
    }

    copyPassword = () => {
        copy(this.state.editedItemProps.pw)
    }
    copyUser = () => {
        copy(this.state.editedItemProps.user)
    }

    toggleEditMode = () => {
        this.setState((oldState) => {
            const newEditMode = !oldState.editMode;
            this.notifyEditMode(this.state.editedItemProps.id, newEditMode);
            return {
                editMode: newEditMode,
                secretVisible: false,
            }
        });
    }

    handleItemPropChange = (dataProp, value) => {
        this.setState((oldState) => {
            const editedItemProps = oldState.editedItemProps;
            let tagsString = oldState.tagsString;
            if (dataProp === "tags") {
                tagsString = value;
                value = tagsStringToTags(value);
            }
            editedItemProps[dataProp] = value;
            return { tagsString, editedItemProps }
        });
        console.debug("edited: ",this.state.editedItemProps)
    }


    cancelItemHandler = () => {
        console.debug("cancel: ");
        this.setState((oldState)=>{return{ editedItemProps:{...this.props.item }}});
        this.toggleEditMode();
    }

    saveItemHandler = () => {
        console.debug("Will save: ", this.state.editedItemProps);
        this.setState((oldState) => {
            return {
                tagsString: tagsToString(oldState.editedItemProps.tags)
            }
        });
        for (const key in this.item) {
            if (key !== 'id') {
                this.item[key] = this.state.editedItemProps[key];
            }
        }
        this.props.setItemHandler(this.item);
        this.toggleEditMode();
    }

    render() {
        const { item, addClasses, isBig, deleteClickHandler } = this.props;
        const { secretVisible, firstItemPropWasClicked, editMode } = this.state;

        const showOtherItemProps = isBig || firstItemPropWasClicked || editMode;
        return (
            <div
                className={["item", ...addClasses].join(' ')}
                key={item.id}
            >
                <ItemProp editMode={editMode} label={'Label'} dataProp={'label'} value={this.state.editedItemProps.label} clickHandler={this.firstItemPropClickHandler} handleItemPropChange={this.handleItemPropChange} />
                {showOtherItemProps && <React.Fragment>
                    <hr />
                    <ItemProp editMode={editMode} label={'User'} dataProp={'user'} value={this.state.editedItemProps.user} handleItemPropChange={this.handleItemPropChange} copyHandler={this.copyUser} />
                    <hr />
                    <ItemProp editMode={editMode} label={'Password'} dataProp={'pw'} addClasses={["clickable"]} value={editMode || secretVisible || this.state.editedItemProps.pw === "" ? this.state.editedItemProps.pw : '*'.repeat(9)} clickHandler={this.secretClickHandler} copyHandler={this.copyPassword} handleItemPropChange={this.handleItemPropChange}/>
                    <hr />
                    <ItemProp editMode={editMode} label={'Tags'} dataProp={'tags'} value={this.state.tagsString} handleItemPropChange={this.handleItemPropChange} />
                    <hr />
                    <ItemProp addClasses={["item-prop__functions"]}>
                        {
                            editMode ?
                                (
                                    <React.Fragment>
                                        <button className="icon" style={{ backgroundImage: 'url(' + deleteSvg + ')' }} onClick={() => deleteClickHandler(this.state.editedItemProps.id)}></button>
                                        <button className="icon" style={{ backgroundImage: 'url(' + checkSvg + ')' }} onClick={this.saveItemHandler}></button>
                                        <button className="icon" style={{ backgroundImage: 'url(' + uneditSvg + ')' }} onClick={this.cancelItemHandler}></button>
                                    </React.Fragment>
                                )
                                :
                                (
                                    <React.Fragment>
                                        <button className="icon" style={{ backgroundImage: 'url(' + deleteSvg + ')' }} onClick={() => deleteClickHandler(this.state.editedItemProps.id)}></button>
                                        <button className="icon" style={{ backgroundImage: 'url(' + editSvg + ')' }} onClick={this.toggleEditMode}></button>
                                    </React.Fragment>
                                )
                        }
                    </ItemProp>
                </React.Fragment>
                }

            </div>
        )
    }
}

export default connect(['isBig'])(Item);
