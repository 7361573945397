import React from 'react';
import './App.css';
import chevronUp from './client/Components/chevron-up.svg'
import TagList from './client/Components/TagList';
import { init, getTags, getItemsForTagsAndSearch, addNewItemHandler, deleteItem, setItem, enterPassword } from './client/Components/selector'
import ItemList from './client/Components/ItemList';
import Intro from './client/Components/Intro';
import { withAlert } from 'react-alert'
import SearchList from './client/Components/SearchList';

const OuterBasicAuthPresent = true;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newItems: [],
      newItemsEditing: new Set(),
      selectedTags: [],
      selectedSearchPatterns: [],
      waiting: false,
      login: true,
      needCredentials: ! OuterBasicAuthPresent,
      showDeselectedTagsOnMobile: false,
    }
  }

  tryInit(username, password) {
    console.info(`Trying to login ${username}.`)
    this.setState(() => {
      return { waiting: true }
    });
    init(username, password).then(() => {
      this.setState((oldState) => {
        console.info("Data arrived.");
        return { waiting: false, needCredentials: false }
      })
    }).catch((e) => {
      this.props.alert.show("Wrong password or user.");
      console.error("Failed to create the database: ", e);
      this.setState(() => {
        return { waiting: false }
      });
    });
  }

  componentDidMount() {
    if (OuterBasicAuthPresent && this.state.login) {
      return this.tryInit(null, null);
    }
  }

  loginHandler = (username, password) => {
    if (this.state.needCredentials) {
      return this.tryInit(username, password)
    } else {
      console.info("Master key entered");
      enterPassword(password).then(() => {
        console.info("Forcing update");
        this.forceUpdate();
        this.setState(() => ({ login: false }))
      }).catch((e) => {
        this.props.alert.show("Master key seems to be wrong.");
        console.error("Error in decryption:", e);
      });
    }
  }

  removeNoneditingNewItems() {
    this.setState((oldState) => {
      return {
        newItems: oldState.newItems
          .filter((i) => oldState.newItemsEditing.has(i.id))
      }
    });
  }

  tagClickHandler = (tag) => {
    this.removeNoneditingNewItems();
    this.setState((oldState) => {
        return {
          newItems: oldState.newItems
            .filter((i) => oldState.newItemsEditing.has(i.id)),
          selectedTags: (
            oldState.selectedTags.includes(tag) ?
              oldState.selectedTags.filter((item) => (item !== tag))
              : [...oldState.selectedTags, tag])
        }
      }
    )
  }

  toggleShowDeselectedTagsOnMobile = () => {
    this.setState((oldState) => ({ showDeselectedTagsOnMobile: !oldState.showDeselectedTagsOnMobile }));
  }

  deleteItemHandler = (itemId) => {
    if (window.confirm('Really delete this item?')) {
      deleteItem(itemId);
      this.setState((oldState) => {
        const newItemsEditing = new Set(oldState.newItemsEditing);
        newItemsEditing.delete(itemId);
        return {
          newItems: oldState.newItems.filter((i) => (i.id !== itemId)),
          newItemsEditing
        };
      });
      this.forceUpdate();
    }
  }

  setItemHandler = (item) => {
    setItem(item);
    this.forceUpdate();
  }

  addNewItemHandler = () => {
    const selectedNonTrivial = this.state.selectedTags.filter((x) => x);
    const newItem = addNewItemHandler(selectedNonTrivial);
    this.setState((oldState) => {
      const newItemsEditing = new Set(oldState.newItemsEditing);
      newItemsEditing.add(newItem.id);
      return {
        newItems: oldState.newItems.concat([newItem]),
        newItemsEditing
      }
    });
    this.forceUpdate();
  }

  notifyEditMode = (itemId, newEditState) => {
    this.setState((oldState) => {
      const newItemsEditing = new Set(oldState.newItemsEditing);
      if (newEditState) {
        newItemsEditing.add(itemId);
      } else {
        newItemsEditing.delete(itemId);
      }
      return {
        newItemsEditing
      };
    });
  }

  /**
   * @param {string[]} searchPatterns
   */
  searchInputHandler = (searchPatterns) => {
    this.removeNoneditingNewItems();
    this.setState(() => {
      return { selectedSearchPatterns: searchPatterns }
    });
    this.forceUpdate();
  }

  render() {
    return (
      <div className="App">
        <header className="App_header">
          <h1>pwsafe</h1>
        </header>
        <main className="content">
          {this.state.waiting ? (
            <div className="loading_icon_wrapper">
              <h3>Loading ... &nbsp;</h3>
              <span className="loading_icon"></span>
            </div>
          ) : (this.state.login ? (
            <div className="loading_icon_wrapper">
              <Intro
                userRequired={this.state.needCredentials}
                loginHandler={this.loginHandler}
              />
            </div>

          ) : (
              <React.Fragment>
                <div className="col col_tag-list">
                  <SearchList searchInputHandler={this.searchInputHandler} />
                  <h2>Tag List</h2>
                  <TagList
                    tags={new Map([...getTags()].filter((t) => this.state.selectedTags.includes(t[0])))}
                    selectedTags={this.state.selectedTags}
                    tagClickHandler={this.tagClickHandler}
                    />
                    <hr></hr>
                    <button className={"hide_on_desktop icon" + (this.state.showDeselectedTagsOnMobile ? "" : " rotate_180deg")} style={{backgroundImage: 'url(' + chevronUp + ')'}}  onClick={this.toggleShowDeselectedTagsOnMobile}></button>
                    <div className={!this.state.showDeselectedTagsOnMobile && "hide_on_mobile"}><TagList
                    tags={new Map([...getTags()].filter((t) => !this.state.selectedTags.includes(t[0])))}
                    selectedTags={this.state.selectedTags}
                    tagClickHandler={this.tagClickHandler}
                  /></div>
                </div>
                <div className="col col_secret-list">
                  <h2>Secrets List</h2>
                  <ItemList
                    items={this.state.newItems}
                    deleteClickHandler={this.deleteItemHandler}
                    setItemHandler={this.setItemHandler}
                    addNewItemHandler={this.addNewItemHandler}
                    allowNewItems={true}
                    notifyEditMode={this.notifyEditMode}
                    />
                  <hr></hr>
                  <ItemList
                    items={this.getOldItems()}
                    deleteClickHandler={this.deleteItemHandler}
                    setItemHandler={this.setItemHandler}
                    addNewItemHandler={this.addNewItemHandler}
                    allowNewItems={false}
                    notifyEditMode={this.notifyEditMode}
                  />
                </div></React.Fragment>

            ))}
        </main>
      </div>
    );
  }
  getOldItems() {
    const items = getItemsForTagsAndSearch(
      this.state.selectedTags, this.state.selectedSearchPatterns
    );
    console.log(items, this.state.newItems);
    const z = items.filter(
      (i) => !this.state.newItems.includes(i));
    console.log(z);
    return z;
  }
}

export default withAlert()(App);