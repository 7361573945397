import React from 'react'
import addSvg from './add.svg'
import Item from './Item';

const ItemList = ({ items, deleteClickHandler, setItemHandler, addNewItemHandler, allowNewItems, notifyEditMode }) => {

    return (
        <React.Fragment>
            <div className={"secret-list"}>
                {allowNewItems ?
                (<div className="item addNewItem">
                    <button className="icon" style={{backgroundImage: 'url(' + addSvg + ')'}} onClick={addNewItemHandler}></button>
                </div>):""
                }
                {items.sort((a,b)=>{ return (String(a.label).toLowerCase() < String(b.label).toLowerCase()?-1:1)}).map((item, i) => {
                    return (<Item key={item.id} addClasses={i === 0 ? ['first-item']:[]} deleteClickHandler={deleteClickHandler} setItemHandler={setItemHandler} notifyEditMode={notifyEditMode} item={item}></Item>)
                })}
            </div>
        </React.Fragment>
    )
}

export default ItemList;